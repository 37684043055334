/* 全局样式 */
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 链接样式 */
a {
  text-decoration: none;
  color: var(--td-brand-color);
}

a:hover {
  color: var(--td-brand-color-hover);
}

/* 数字显示样式 */
.t-card__body--number {
  font-size: 24px;
  font-weight: 500;
  color: var(--td-text-color-primary);
}

/* 暗色主题适配 */
.t-dark-mode {
  background-color: var(--td-bg-color-page);
  color: var(--td-text-color-primary);
}

/* 布局样式 */
.t-layout {
  min-height: 100vh;
}

.t-layout__header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--td-bg-color-container);
  box-shadow: var(--td-shadow-1);
}

.t-layout__content {
  background-color: var(--td-bg-color-page);
}

.t-layout__footer {
  background-color: var(--td-bg-color-container);
  padding: 24px;
  color: var(--td-text-color-secondary);
}

/* 表格样式优化 */
.t-table {
  background-color: var(--td-bg-color-container);
}

.t-table th {
  background-color: var(--td-bg-color-container-hover);
}

/* 搜索结果样式 */
.t-popup__content {
  background-color: var(--td-bg-color-container);
  box-shadow: var(--td-shadow-2);
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .t-layout__header {
    padding: 0 8px;
  }

  .t-layout__content {
    padding: 16px 8px;
  }

  .t-card {
    margin-bottom: 16px;
  }
}


/* Card组件主题适配 */
.t-card {
  background-color: var(--td-bg-color-container);
  /* border: 1px solid var(--td-component-border);
  box-shadow: var(--td-shadow-1);
  transition: box-shadow 0.2s ease-in-out; */
}
/* 
.t-card {
  background-color: var(--td-bg-color-container);
  border: 1px solid var(--td-component-border);
  box-shadow: var(--td-shadow-1);
  transition: box-shadow 0.2s ease-in-out;
}

.t-card:hover {
  box-shadow: var(--td-shadow-2);
}

.t-card__header {
  border-bottom: 1px solid var(--td-component-border);
  padding: var(--td-comp-paddingTB-l) var(--td-comp-paddingLR-l);
}

.t-card__title {
  color: var(--td-text-color-primary);
  font-size: var(--td-font-size-body-large);
  font-weight: var(--td-font-weight-medium);
}

.t-card__subtitle {
  color: var(--td-text-color-secondary);
  font-size: var(--td-font-size-body-medium);
  margin-top: var(--td-comp-margin-xs);
}

.t-card__body {
  color: var(--td-text-color-primary);
  padding: var(--td-comp-paddingTB-l) var(--td-comp-paddingLR-l);
}

.t-card__footer {
  border-top: 1px solid var(--td-component-border);
  color: var(--td-text-color-secondary);
  padding: var(--td-comp-paddingTB-s) var(--td-comp-paddingLR-l);
} */
